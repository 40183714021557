import React, { useState } from 'react'
import './Footer.css'
import pin from '../../assets/icons/pin.svg'
import tele from '../../assets/icons/phone-call.svg'
import emailjs from 'emailjs-com'
import { useTranslation } from 'react-i18next'
import AcLogo from '../../assets/images/autoconnect-logo.png'

function Footer() {
  const { t, i18n } = useTranslation()

  const [email, setEmail] = useState('')
  const handleFormSubmit = () => {
    console.log('hello')
    emailjs.send('service_gposcl9', 'template_uzoilkh', { email }, 'user_1f7hVbZOLajksVlCmmS2t').then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text, { email })
      },
      (err) => {
        console.log('FAILED...', err)
      }
    )
  }
  const handleChange = (event) => {
    setEmail(event.target.value)
  }
  return (
    <>
      <div className='footer--container'>
        <div className='footer--content'>
          <div className='footer--subscribe--to--newsletter'>
            <img className="logo--footer--desktop" src={AcLogo}></img>
            <div className='footer--subscribe--header'>{t('Subscribe to our Newsletter')}</div>
            <div className='footer--subscribe--input'>
              <input type='text' value={email} name='email' placeholder={t('Your Email Address')} onChange={handleChange} />
            </div>
            <div className='footer--subscribe--button--container'>
              <button
                type='submit'
                className='footer--subscribe--button'
                onClick={() => {
                  handleFormSubmit()
                  setEmail('')
                }}
              >
                {t('Subscribe ')}
              </button>
            </div>

            {/* <div className="footer--social--links--container">
                            <ul className="footer--social--links nav">
                                <li className="footer--social--link">
                                    <a href="https://m.facebook.com/beyondcreation.mena/" target="_blank" rel="noreferrer">
                                        <img src={fbIcon} alt="Facebook" />
                                    </a>
                                </li>
                                <li className="footer--social--link">
                                    <a href="https://www.instagram.com/beyondcreation.digital/?hl=en" target="_blank" rel="noreferrer">
                                        <img src={instaIcon} alt="Instagram" />
                                    </a>
                                </li>
                                <li className="footer--social--link">
                                    <a href="https://www.linkedin.com/company/beyond-creation/about/" target="_blank" rel="noreferrer">
                                        <img src={linkedIn} alt="LinkedIn" />
                                    </a>
                                </li>
                            </ul>
                        </div> */}
          </div>
          <div className='footer--site--map--container'>
            {/* <ul className="footer--site--map--links--list nav">
                            <li className="footer--site--map--link">
                                <a href="https://beyond-creation.net/" target="_blank" rel="noreferrer">Home</a> 
                            </li>
                            <li className="footer--site--map--link">
                                <a href="https://beyond-creation.net/about" target="_blank" rel="noreferrer">About Us</a>
                            </li>
                            <li className="footer--site--map--link">
                                <a href="https://beyond-creation.net/services" target="_blank" rel="noreferrer">Services</a>
                            </li>
                            <li className="footer--site--map--link">
                                <a href="https://beyond-creation.net/work" target="_blank" rel="noreferrer">Work</a>
                            </li>
                            <li className="footer--site--map--link">
                                <a href="https://beyond-creation.net/careers" target="_blank" rel="noreferrer">Careers</a>
                            </li>
                            <li className="footer--site--map--link">
                                <a href="https://beyond-creation.net/contact" target="_blank" rel="noreferrer">Contact Us</a>
                            </li>
                        </ul> */}
          </div>
          <div className='footer--contact--info--container'>
            <img className="logo--footer--mobile" src={AcLogo}></img>
            <div className='footer--contact--location--container'>
              <div className='footer--contact--location--icon'>
                <a href='https://goo.gl/maps/kv1mdivWK5vzrCto7' target='_blank' rel='noreferrer'>
                  <img src={pin} alt='Location pin' />
                </a>
              </div>
              <div className='footer--contact--location--text--container'>
                <div className='footer--contact--location--text'>
                  <span>{t('Egypt Office')}: </span>
                  <div>
                    {t('95D, El-Marghany St., Helioplis, Cairo.')}
                  </div>
                </div>
                <div className='footer--contact--location--text'>
                  <span>{t('UAE Office')}: </span>
                  <div>
                    {t('DMCC Business Centre, AG Tower, Dubai.')}
                  </div>
                </div>
              </div>
            </div>
            <div className='footer--contact--telephone--container'>
              <div className='footer--contact--telephone--icon'>
                <img src={tele} alt='Telephone' />
              </div>
              <div className='footer--contact--telephone--text--container'>
                <div className='footer--contact--telephone--text' dir='ltr'>
                  <a href="tel:+20 100 1255800">
                    +20 100 1255800
                  </a>
                </div>
                <div className='footer--contact--telephone--text' dir='ltr'>
                  <a href="tel:+971 55 352 9955">
                    +971 55 352 9955
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer--copyrights--container'>
          <p className='footer--copyrights--container--text'>
            {t('Copyrights-')} &copy; {t('2021. All Rights Reserved')}
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer
