import React, { useState, useRef } from 'react'
import './DealerGuide.css'
import { Formik, useField } from 'formik'
import * as Yup from 'yup'
import arrow from '../../assets/icons/slide-arrow.svg'
import emailjs from 'emailjs-com'
import { useTranslation } from 'react-i18next'

function DealerGuide(props) {
  const { t, i18n } = useTranslation()

  const [thankyou, setthankyou] = useState(false)
  const linkRef = useRef()
  const handeFormSubmit = (data) => {
    data.type = 'Guide Downlaod'
    emailjs.send('service_tlfiu0x', 'template_880l8z2', data, 'user_1f7hVbZOLajksVlCmmS2t').then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text, data)
      },
      (err) => {
        console.log('FAILED...', err)
      }
    )
  }
  const downloadPdf = () => {
    const link = document.createElement('a')
    if (i18n.language == 'ar') {
      link.href = 'https://autoconnect.digital/autoguide_ar.pdf'
      link.download = 'autoguide_ar.pdf'
    } else {
      link.href = 'https://autoconnect.digital/autoguide_en.pdf'
      link.download = 'autoguide_en.pdf'
    }

    link.target = '_blank'
    link.click()
    linkRef.current = link
  }
  const MyTextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className='text-input' {...field} {...props} />
        {meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
      </>
    )
  }
  // const MyCheckbox = ({ children, ...props }) => {
  //   // React treats radios and checkbox inputs differently other input types, select, and textarea.
  //   // Formik does this too! When you specify `type` to useField(), it will
  //   // return the correct bag of props for you -- a `checked` prop will be included
  //   // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  //   const [field, meta] = useField({ ...props, type: 'checkbox' })
  //   return (
  //     <div>
  //       <label className='checkbox-input'>
  //         <input type='checkbox' {...field} {...props} />
  //         {children}
  //       </label>
  //       {meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
  //     </div>
  //   )
  // }
  return (
    <>
      <a hidden rel='noreferrer' ref={linkRef} href={linkRef.href}>
        download
      </a>
      <div className='dealer--guide--container'>
        {thankyou ? (
          <div className='dealer--guide--thankyou'>
            <h3>{t('Thank you!')}</h3>
            <h5>
              {t('If the file did not download automatically')}{' '}
              {i18n.language == 'ar' ? (
                <a href='https://autoconnect.digital/autoguide_ar.pdf' target='_blank' rel='noreferrer'>
                  {t('Click here!')}
                </a>
              ) : (
                <a href='https://autoconnect.digital/autoguide_en.pdf' target='_blank' rel='noreferrer'>
                  {' '}
                  {t('Click here!')}{' '}
                </a>
              )}
            </h5>
          </div>
        ) : (
          <Formik
            // , terms: false
            initialValues={{ fullname: '', email: '', company: '', title: '' }}
            validationSchema={Yup.object({
              fullname: Yup.string().required(t('Required')),
              email: Yup.string().email(t('email_validation')).required(t('Required')),
              company: Yup.string().required(t('Required')),
              title: Yup.string().required(t('Required')),
              // terms: Yup.boolean().required('Required').oneOf([true], 'You must accept the terms and conditions.'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setthankyou(true)
              downloadPdf()
              handeFormSubmit(values)
              setSubmitting(false)
              setTimeout(() => {
                setthankyou(false)
                props.handleBack()
              }, 180000)
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <div className='dealer--guide--form--text--container'>
                  <div className='dealer--guide--form--back--button--container'>
                    <button className='dealer--guide--form--back--button' onClick={() => props.handleBack()}>
                      <img src={arrow} alt='Back arrow' />
                    </button>
                  </div>
                  <div className='dealer--guide--form--header--container'>
                    <h1 className='dealer--guide--form--header'>{t('download-')}</h1>
                    <h1 className='dealer--guide--form--header__colored'>{t('Guide ')}</h1>
                  </div>
                  <div className='dealer--guide--paragraph--container'>
                    <p>{t('Please fill-in the below form to download your copy')}</p>
                  </div>
                </div>
                <div className='dealer--guide--form--inputs--container'>
                  <div className='form--input--container'>
                    <MyTextInput label='' name='fullname' type='text' placeholder={t('Full Name')} className='from--text--input' />
                  </div>
                  {/* <div className='form--input--container'>
                  <MyTextInput label='' name='phonenumber' type='text' placeholder='Phone Number' className='from--text--input' />
                </div> */}
                  <div className='form--input--container'>
                    <MyTextInput label='' name='email' type='text' placeholder={t('E-mail Address')} className='from--text--input' />
                  </div>
                  <div className='form--input--container'>
                    <MyTextInput label='' name='company' type='text' placeholder={t('Company placeholder')} className='from--text--input' />
                  </div>
                  <div className='form--input--container'>
                    <MyTextInput label='' name='title' type='text' placeholder={t('Title placeholder')} className='from--text--input' />
                  </div>
                  {/* <div className='form--checkbox--container'>
                  <MyCheckbox name='terms'>
                    <span> I accept the terms and conditions</span>
                  </MyCheckbox>
                </div> */}
                </div>
                <div className='form--submit--button--container'>
                  <button className='from--submit--button' type='submit'>
                    {t('Download-')}
                  </button>
                  {/* <button className="from--submit--button" type="submit">
                           حمل النسخه العربية
                        </button> */}
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </>
  )
}

export default DealerGuide
