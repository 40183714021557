import React from "react";
import FeaturesBlock from "../FeaturesBlock/FeaturesBlock";
import "./Features.css";
import { useTranslation } from "react-i18next";

function Features() {
   // eslint-disable-next-line
   const { t, i18n } = useTranslation();

   const features1 = {
      header_1: t("Customer"),
      header_2: t("Operation"),
      listItems_customer: [t("li_vehicles_customer_1"),t("li_vehicles_customer_2"),t("li_vehicles_customer_3"),t("li_vehicles_customer_4")],
      listItems_operation: [t("li_vehicles_operation_1"), t("li_vehicles_operation_2"), t("li_vehicles_operation_3"), t("li_vehicles_operation_4"), t("li_vehicles_operation_5")],
   };
   const features2 = {
      header: t("Financing & Insurance"),
      header_1: t("Customer"),
      header_2: t("Operation"),
      listItems_customer: [
         t("li_sales_customer_1"),
         t("li_sales_customer_2"),
         t("li_sales_customer_3"),
         t("li_sales_customer_4"),
         t("li_sales_customer_5"),
         t("li_sales_customer_6"),
      ],
      listItems_operation: [
         t("li_sales_operation_1"),
         t("li_sales_operation_2"),
         t("li_sales_operation_3"),
         t("li_sales_operation_4"),
         t("li_sales_operation_5"),
         t("li_sales_operation_6"),
         t("li_sales_operation_7"),
      ]
   };
   const features3 = {
      header: t("After Sales features"),
      header_1: t("Customer"),
      header_2: t("Operation"),
      listItems_customer: [
         t("li_finance_customer_1"),
         t("li_finance_customer_2"),
         t("li_finance_customer_3"),
      ],
      listItems_operation : [
         t("li_finance_operation_1"),
         t("li_finance_operation_2"),
         t("li_finance_operation_3"),
      ]
   };
   const features4 = {
      header: t("Backend features"),
      listItems: [t("be-1"), t("be-2"), t("be-3"), t("be-4"), t("be-5"), t("be-6"), t("be-7"), t("be-8")],
   };
   return (
      <>
         <div className="features--container">
            <div className="features--text--container">
               <div className="features--header--container">
                  <h1 className="features--header">{t("Features-")}</h1>
               </div>
               <div className="features--paragraph--container">
                  <p>{t("digital foundation")}</p>
               </div>
            </div>
            <div className="features--blocks--container">
               <div className='features--block--header--container'>
                  <h1 className='features--block--header--first'>{t("Shopping Experience")}</h1>
               </div>
               <FeaturesBlock data={features1} />

               <div className='features--block--header--container'>
                  <h1 className='features--block--header--first'>{t("After Sales")}</h1>
               </div>
               <FeaturesBlock data={features2} />

               <div className='features--block--header--container'>
                  <h1 className='features--block--header--first'>{t("Financing")}</h1>
               </div>
               <FeaturesBlock data={features3} />
               
            </div>
         </div>
      </>
   );
}

export default Features;
