import React, { useContext } from 'react';
import "./MobileNav.css";
import { Link, NavLink } from 'react-router-dom';
import BcLogo from "../../assets/images/bc-logo-white.png"
import AcLogo from "../../assets/images/autoconnect-logo.png"
import { scroller } from "react-scroll";
import { showAreYouInterestedContext } from "../AreYouInterested/AreYouInterestedFormProvider";
import { useTranslation } from "react-i18next";

function MobileNav({open,handleOpen}) {

    const { t, i18n } = useTranslation();
    const changeLanguage = (lang) =>
      {
         console.log(i18n.language);
         i18n.changeLanguage(lang);
         handleOpen();
         window.location.reload();
      }
    const scrollToRequestDemo = () => {
        scroller.scrollTo("scrollTo--form", {
           duration: 500,
           delay: 0,
           smooth: "easeInOutQuad",
        });
        hadleShowRequestDemoForm();
     };
     const scrollToDealerGuide = () => {
        scroller.scrollTo("scrollTo--form", {
           duration: 500,
           delay: 0,
           smooth: "easeInOutQuad",
        });
        hadleShowDealerGuideForm();
     };
     const { areYouInterested, dealerGuide, requestDemo } = useContext(showAreYouInterestedContext);
     // eslint-disable-next-line
     const [showRequestDemoForm, setshowRequestDemoForm] = requestDemo;
     // eslint-disable-next-line
     const [showDealerGuideForm, setshowDealerGuideForm] = dealerGuide;
     // eslint-disable-next-line
     const [showAreYouInterested, setshowAreYouInterested] = areYouInterested;

     function hadleShowRequestDemoForm() {
        setshowRequestDemoForm(true);
        setshowDealerGuideForm(false);
        setshowAreYouInterested(false);
     }
     function hadleShowDealerGuideForm() {
        setshowRequestDemoForm(false);
        setshowDealerGuideForm(true);
        setshowAreYouInterested(false);
     }
    return (
        <>
            <div className={open ? "mobile--nav--container opened":"mobile--nav--container"}>
                <div className="mobile--nav--logos--container">
                    <div className="beyond-creation--logo separator" onClick={()=>(handleOpen())}>
                        <a href="https://beyond-creation.net/" target="_blank" rel="noreferrer">
                            <img src={BcLogo} alt="Bc logo" />
                        </a>
                    </div>
                    <div className="auto-connect--logo" onClick={()=>(handleOpen())}>
                        <Link to={{ pathname:"/" }}>
                            <img src={AcLogo} alt="logo" />
                        </Link>
                    </div>
                </div>
                <ul className="nav mobile--nav">
                    <li className="nav-item" onClick={()=>(handleOpen())}>
                        <NavLink className="mobile--nav--link" to="/" exact={true} activeClassName="nav--selected">{t("About AutoConnect")}</NavLink>
                    </li>
                    <li className="nav-item" onClick={()=>(handleOpen())}>
                        <NavLink className="mobile--nav--link" to="/features"  exact={true} activeClassName="nav--selected">{t("Features-")}</NavLink>
                    </li>
                    <li className="nav-item" onClick={()=>(handleOpen())}>
                        <NavLink className="mobile--nav--link" to="/guide" exact={true} activeClassName="nav--selected">{t("Digital Transformation Guide")}</NavLink>
                    </li>
                </ul>
                <div className="mobile--nav--buttons--container">
                    <button className="mobile--nav--request--demo--button" onClick={()=>{handleOpen();scrollToRequestDemo()}}>
                        {t("book a demo")}
                    </button>
                    <button className="mobile--nav--dealer--guide--button" onClick={()=>{handleOpen();scrollToDealerGuide()}}>
                        {t("Download Guide")}
                    </button>
                    {i18n.language == "en" ? 
                        <div className="language--link arabic" onClick={() => changeLanguage('ar')}>
                            عربي
                        </div> :
                        <div className="language--link" onClick={() => changeLanguage('en')}>
                            English
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default MobileNav
