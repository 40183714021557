import React from 'react'
import './AutoConnectFeatures.css'
import { useTranslation } from 'react-i18next'
// import { Link } from "react-router-dom";

function AutoConnectFeatures() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()

  return (
    <>
      <div className='auto--connect--features--container'>
        <div className='auto--connect--features--text--container'>
          <h1 className='auto--connect--features--header'>{t('AutoConnect')}</h1>
          <h1 className='auto--connect--features--header'>{t('FEATURES ')}</h1>
          <div className='auto--connect--features--paragraph'>
            <p>{t('features sub')}</p>
          </div>
        </div>
        {/* <div className="auto--connect--features--button--container">
               <Link className="auto--connect--features--button--container" to={{ pathname: "/features" }}>
                  <button className="auto--connect--features--button">{t("Learn More")}</button>
               </Link>
            </div> */}
      </div>
    </>
  )
}

export default AutoConnectFeatures
