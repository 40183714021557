import React, { useEffect } from 'react'
import BannerImage from '../../components/BannerImage/BannerImage'
import bCarBg from '../../assets/images/blueCar.jpg'
import Guide from '../../components/Guide/Guide'
import { useTranslation } from 'react-i18next'

function GuidePage() {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <BannerImage header={t('Guide Header')} secondaryHeader={t('Guide Subheader')} backgroundImage={bCarBg} />
      <Guide />
    </>
  )
}

export default GuidePage
