import React from "react";
import "./BannerImage.css";

function BannerImage({header, secondaryHeader, backgroundImage}) {
   return (
      <>
         <div className="banner--image--container" style={{backgroundImage:`url(${backgroundImage})`}}>
            <div className="banner--image--text--container">
               <h1 className="banner--image--header">
                 {header}
               </h1>
               {secondaryHeader ? 
               <h3 className="banner--image--secondary--header">
                   {secondaryHeader}
               </h3> 
               : ""}
            </div>
         </div>
      </>
   );
}

export default BannerImage;
