import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './Pages/HomePage/HomePage'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import AreYouInterested from './components/AreYouInterested/AreYouInterested'
import AreYouInterestedFormProvider from './components/AreYouInterested/AreYouInterestedFormProvider'
import FeaturesPage from './Pages/FeaturesPage/FeaturesPage'
import GuidePage from './Pages/GuidePage/GuidePage'
import { useTranslation } from 'react-i18next'

function App() {
  const { t, i18n } = useTranslation()
  return (
    <div className='App' dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
      <Router basename={process.env.PUBLIC_URL}>
        <AreYouInterestedFormProvider>
          <Header />
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/features' component={FeaturesPage} />
            <Route exact path='/guide' component={GuidePage} />
          </Switch>
          <AreYouInterested />
        </AreYouInterestedFormProvider>
        <Footer />
      </Router>
    </div>
  )
}

export default App
