import React, { useState } from 'react'
import './RequestDemo.css'
import { Formik, useField } from 'formik'
import * as Yup from 'yup'
import arrow from '../../assets/icons/slide-arrow.svg'
import emailjs from 'emailjs-com'
import { useTranslation } from 'react-i18next'

function RequestDemo(props) {
  const [thankyou, setthankyou] = useState(false)
  const MyTextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className='text-input' {...field} {...props} />
        {meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
      </>
    )
  }
  //   const MyCheckbox = ({ children, ...props }) => {
  //     // React treats radios and checkbox inputs differently other input types, select, and textarea.
  //     // Formik does this too! When you specify `type` to useField(), it will
  //     // return the correct bag of props for you -- a `checked` prop will be included
  //     // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  //     const [field, meta] = useField({ ...props, type: 'checkbox' })
  //     return (
  //       <div>
  //         <label className='checkbox-input'>
  //           <input type='checkbox' {...field} {...props} />
  //           {children}
  //         </label>
  //         {meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
  //       </div>
  //     )
  //   }
  const { t, i18n } = useTranslation()

  const handeFormSubmit = (data) => {
    data.type = 'Request Demo'
    emailjs.send('service_tlfiu0x', 'template_880l8z2', data, 'user_1f7hVbZOLajksVlCmmS2t').then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text, data)
      },
      (err) => {
        console.log('FAILED...', err)
      }
    )
  }
  return (
    <>
      <div className='request--demo--container'>
        {thankyou ? (
          <div className='request--demo--thankyou'>
            <h3>{t('thank_you_book_appointment_1')}</h3>
            <h3>{t('thank_you_book_appointment_2')}</h3>
          </div>
        ) : (
          <Formik
            // , terms: false
            initialValues={{ fullname: '', phonenumber: '', email: '', company: '', title: '' }}
            validationSchema={Yup.object({
              fullname: Yup.string().required(t('Required')),
              phonenumber: Yup.string().min(11, t('phone_validation')).required(t('Required')),
              email: Yup.string().email(t('email_validation')).required(t('Required')),
              company: Yup.string().required(t('Required')),
              title: Yup.string().required(t('Required')),
              // terms: Yup.boolean().required('Required').oneOf([true], 'You must accept the terms and conditions.'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setthankyou(true)
              handeFormSubmit(values)
              setSubmitting(false)
              setTimeout(() => {
                setthankyou(false)
                props.handleBack()
              }, 180000)
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <div className='request--demo--form--text--container'>
                  <div className='request--demo--form--back--button--container'>
                    <button className='request--demo--form--back--button' onClick={() => props.handleBack()}>
                      <img src={arrow} alt='Back arrow' />
                    </button>
                  </div>
                  <div className='request--demo--form--header--container'>
                    <h1 className='request--demo--form--header'>{t('Book a')}</h1>
                    <h1 className='request--demo--form--header__colored'>{t('Demo ')}</h1>
                  </div>
                  <div className='request--demo--paragraph--container'>
                    <p>{t('Please fill-in the below form to book an appointment')}</p>
                  </div>
                </div>
                <div className='request--demo--form--inputs--container'>
                  <div className='form--input--container'>
                    <MyTextInput label='' name='fullname' type='text' placeholder={t('Full Name')} className='from--text--input' />
                  </div>
                  <div className='form--input--container'>
                    <MyTextInput label='' name='phonenumber' type='text' placeholder={t('Phone Number')} className='from--text--input' />
                  </div>
                  <div className='form--input--container'>
                    <MyTextInput label='' name='email' type='text' placeholder={t('E-mail Address')} className='from--text--input' />
                  </div>
                  <div className='form--input--container'>
                    <MyTextInput label='' name='company' type='text' placeholder={t('Company placeholder')} className='from--text--input' />
                  </div>
                  <div className='form--input--container'>
                    <MyTextInput label='' name='title' type='text' placeholder={t('Title placeholder')} className='from--text--input' />
                  </div>
                  {/* <div className='form--checkbox--container'>
                  <MyCheckbox name='terms'>
                    <span> I accept the terms and conditions</span>
                  </MyCheckbox>
                </div> */}
                </div>
                <div className='form--submit--button--container'>
                  <button className='from--submit--button' type='submit'>
                    {t('Book Appointment')}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </>
  )
}

//   return (
//     <>
//       <div className='request--demo--container'>
//         <Formik
//           handleSubmit={handeFormSubmit}
//           initialValues={{ fullname: '', phonenumber: '', email: '', company: '', title: '', terms: false }}
//           validationSchema={Yup.object({
//             fullname: Yup.string().min(5, 'Must be 5 characters or more').required('Required'),
//             phonenumber: Yup.string().required('Required'),
//             email: Yup.string().email('Invalid email address').required('Required'),
//             company: Yup.string().required('Required'),
//             title: Yup.string().required('Required'),
//             terms: Yup.boolean().required('Required').oneOf([true], 'You must accept the terms and conditions.'),
//           })}
//           onSubmit={(values, { setSubmitting }) => {
//             setTimeout(() => {
//               alert(JSON.stringify(values, null, 2))
//               handeFormSubmit(values)
//               setSubmitting(false)
//             }, 400)
//           }}
//         >
//           {(formik) => (
//             <form onSubmit={formik.handleSubmit}>
//               <div className='request--demo--form--text--container'>
//                 <div className='request--demo--form--back--button--container'>
//                   <button className='request--demo--form--back--button' onClick={() => props.handleBack()}>
//                     <img src={arrow} alt='Back arrow' />
//                   </button>
//                 </div>
//                 <div className='request--demo--form--header--container'>
//                   <h1 className='request--demo--form--header'>Request</h1>
//                   <h1 className='request--demo--form--header__colored'>A Demo</h1>
//                 </div>
//                 <div className='request--demo--paragraph--container'>
//                   <p>Please fill-in the below form to recieve a demo version of AutoConnect</p>
//                 </div>
//               </div>
//               <div className='request--demo--form--inputs--container'>
//                 <div className='form--input--container'>
//                   <MyTextInput label='' name='fullname' type='text' placeholder='Full Name' className='from--text--input' />
//                 </div>
//                 <div className='form--input--container'>
//                   <MyTextInput label='' name='phonenumber' type='text' placeholder='Phone Number' className='from--text--input' />
//                 </div>
//                 <div className='form--input--container'>
//                   <MyTextInput label='' name='email' type='text' placeholder='E-mail Address' className='from--text--input' />
//                 </div>
//                 <div className='form--input--container'>
//                   <MyTextInput label='' name='company' type='text' placeholder='Company' className='from--text--input' />
//                 </div>
//                 <div className='form--input--container'>
//                   <MyTextInput label='' name='title' type='text' placeholder='Title' className='from--text--input' />
//                 </div>
//                 <div className='form--checkbox--container'>
//                   <MyCheckbox name='terms'></MyCheckbox>
//                   <span> I accpet the terms and conditions</span>
//                 </div>
//               </div>
//               <div className='form--submit--button--container'>
//                 <button className='from--submit--button' type='submit'>
//                   Send request
//                 </button>
//               </div>
//             </form>
//           )}
//         </Formik>
//       </div>
//     </>
//   )
// }

export default RequestDemo
