import React, { useState } from 'react'
import "./Burger.css"
import MobileNav from './MobileNav';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

function Burger() {
    const [burgeropen, setburgeropen] = useState(false);
    burgeropen ? disableBodyScroll(document) : enableBodyScroll(document)
    function setOpenFalse(){
        setburgeropen(false);
    }
    return (
        <>
            <div className="burger--container" onClick={()=>setburgeropen(!burgeropen)}>
                <div className={burgeropen?"opened":""}/>
                <div className={burgeropen?"opened":""}/>
                <div className={burgeropen?"opened":""}/>
            </div>
            <MobileNav 
            open={burgeropen}
            handleOpen={setOpenFalse}/>
        </>
    )
}

export default Burger
