import React from 'react'
import "./BenefitsCard.css"

function BenefitsCard({icon,text}) {
    return (
        <>
            <div className="benefits--card--container">
                <div className="benefits--card--icon--container">
                    <img src={icon} alt="Benefits Icon" />
                </div>
                <div className="benefits--card--text--container">
                    {text}
                </div>
            </div>
        </>
    )
}

export default BenefitsCard
