import React from 'react'
import { createContext, useState } from 'react';

//create a context, with createContext api
export const showAreYouInterestedContext = createContext();

function AreYouInterestedFormProvider(props) {
    const [showRequestDemoForm, setshowRequestDemoForm] = useState(false);
    const [showDealerGuideForm, setshowDealerGuideForm] = useState(false);
    const [showAreYouInterested, setshowAreYouInterested] = useState(true);
    return (
        <>
            <showAreYouInterestedContext.Provider 
            value={{
                requestDemo:[showDealerGuideForm, setshowDealerGuideForm],
                dealerGuide:[showRequestDemoForm,setshowRequestDemoForm],
                areYouInterested:[showAreYouInterested,setshowAreYouInterested]
                }}>
                {props.children}
            </showAreYouInterestedContext.Provider> 
        </>
    )
}

export default AreYouInterestedFormProvider
