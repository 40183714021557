import React from 'react'
import "./Square.css"

function Square({squareClasses}) {
    return (
        <>
            <div className={`square--ge ${squareClasses}`}></div>
        </>
    )
}

export default Square
