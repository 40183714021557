import React from 'react'
import './BannerSlider.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, EffectFade } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css'
import acLogoBlack from '../../assets/images/autoconnect-logo-b.png'
import { useTranslation } from 'react-i18next'

SwiperCore.use([EffectFade, Navigation, Pagination, Scrollbar])

function Banner() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  console.log(i18n.language)
  return (
    <>
      <div className='banner--container'>
        <div className='banner--text--header--container'>
          <img src={acLogoBlack} alt='Logo' />
        </div>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          effect={'fade'}
          navigation={{
            nextEl: '.banner--text--slider--button--next',
            prevEl: '.banner--text--slider--button--prev',
          }}
          direction={'vertical'}
          pagination={{
            el: '.banner--text--slider--pagination',
            clickable: true,
            type: 'fraction',
            renderBullet: (index, className) => {
              return '<span class="' + className + '">' + (index + 1) + '</span>'
            },
          }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          breakpoints={{
            320: {
              direction: 'horizontal',
            },
            1025: {
              direction: 'vertical',
            },
          }}
        >
          <SwiperSlide>
            <div className='banner--text--container'>
              <p className='banner--text--paragraph'>{t('home subtitle')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='banner--text--container'>
              <p className='banner--text--paragraph'>{t('home subtitle')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='banner--text--container'>
              <p className='banner--text--paragraph'>{t('home subtitle')}</p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className='banner--text--slider--pagination'></div>
        <div className='swiper-button-next banner--text--slider--button--prev'></div>
        <div className='swiper-button-prev banner--text--slider--button--next'></div>
      </div>
    </>
  )
}

export default Banner
