import React from 'react'
import { useTranslation } from 'react-i18next'
import Square from '../Square/Square'
import './AutoExperience.css'
function AutoExperience() {
  const { t, i18n } = useTranslation()

  return (
    <>
      <div className='auto-experience--container'>
        <div className='auto-experience--square--container'>
          <Square squareClasses={'medium'} />
          <Square squareClasses={'medium dark'} />
          <Square squareClasses={'medium blank'} />
          <Square squareClasses={'medium'} />
        </div>
        <div className='auto-experience--header--container separator-dark'>
          <h1 className='auto-experience--header'>{t('AUTOMOTIVE-')}</h1>
          <h1 className='auto-experience--header'>{t('EXPERIENCE-')}</h1>
          <h3 className='auto-experience--sub-header'>{t('15 years of experience')}</h3>
        </div>
        <div className='auto-experience--paragraph'>
          <p>{t('extensive expertise')}</p>
        </div>
        <div className='auto-experience--square--container--bottom--right'>
          <Square squareClasses={'medium margin-top-50'} />
          <Square squareClasses={'medium'} />
        </div>
      </div>
    </>
  )
}

export default AutoExperience
