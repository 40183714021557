import React, { useContext } from 'react'
import DealerGuide from '../DealerGuide/DealerGuide'
import RequestDemo from '../RequestDemo/RequestDemo'
import './AreYouInterested.css'
import { showAreYouInterestedContext } from './AreYouInterestedFormProvider'
import { useTranslation } from 'react-i18next'

function AreYouInterested() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()

  const { areYouInterested, dealerGuide, requestDemo } = useContext(showAreYouInterestedContext)
  const [showRequestDemoForm, setshowRequestDemoForm] = requestDemo
  const [showDealerGuideForm, setshowDealerGuideForm] = dealerGuide
  const [showAreYouInterested, setshowAreYouInterested] = areYouInterested

  function handleBackToAreYouInterested() {
    setshowRequestDemoForm(false)
    setshowDealerGuideForm(false)
    setshowAreYouInterested(true)
  }
  function hadleShowRequestDemoForm() {
    setshowRequestDemoForm(true)
    setshowDealerGuideForm(false)
    setshowAreYouInterested(false)
  }
  function hadleShowDealerGuideForm() {
    setshowRequestDemoForm(false)
    setshowDealerGuideForm(true)
    setshowAreYouInterested(false)
  }
  return (
    <>
      {showAreYouInterested ? (
        <div className='are--you--interested--container scrollTo--form'>
          <div className='are--you--interested--text--container'>
            <div className='are--you--interested--header--container'>
              <h1 className='are--you--interested--header'>{t('Are you Interested?')}</h1>
            </div>
          </div>
          <div className='are--you--interested--cards--container'>
            <div className='are--you--interested--card--container'>
              <div className='are--you--interested--card--header--container'>
                <h1 className='are--you--interested--card--header'>{t('Download ')}</h1>
                <h1 className='are--you--interested--card--header--blue'>{t('Guide ')}</h1>
              </div>
              <div className='are--you--interested--card--paragraph--container'>
                <p>{t('read oem')}</p>
              </div>
              <div className='are--you--interested--card--button--container'>
                <button className='are--you--interested--button--dealer--guide' onClick={() => hadleShowDealerGuideForm()}>
                  {t('Download Now')}
                </button>
              </div>
            </div>
            <div className='are--you--interested--card--container'>
              <div className='are--you--interested--card--header--container'>
                <h1 className='are--you--interested--card--header'>{t('Book a')} </h1>
                <h1 className='are--you--interested--card--header--blue'>{t('Demo ')}</h1>
              </div>
              <div className='are--you--interested--card--paragraph--container'>
                <p>{t('explore book')}</p>
              </div>
              <div className='are--you--interested--card--button--container'>
                <button className='are--you--interested--button--request--demo' onClick={() => hadleShowRequestDemoForm()}>
                  {t('Book Now')}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {showRequestDemoForm ? (
        <div className='are--you--interested--form--container scrollTo--form'>
          <RequestDemo handleBack={handleBackToAreYouInterested} />
        </div>
      ) : (
        ''
      )}
      {showDealerGuideForm ? (
        <div className='are--you--interested--form--container scrollTo--form'>
          <DealerGuide handleBack={handleBackToAreYouInterested} />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default AreYouInterested
