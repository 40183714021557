import React from 'react'
import './Guide.css'
import MediaQuery from 'react-responsive'
import email from '../../assets/icons/email.svg'
import print from '../../assets/icons/printing.svg'
import save from '../../assets/icons/ribbon.svg'
import fbBlue from '../../assets/icons/facebook-blue.svg'
import linkedInBlue from '../../assets/icons/linkedin-blue.svg'
import twitterBlue from '../../assets/icons/twitter-blue.svg'
import emailBlue from '../../assets/icons/email-blue.svg'
import oem from '../../assets/images/oem.jpg'
import digitalTransformation from '../../assets/images/digital-transformation.jpg'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share'
import { useTranslation } from 'react-i18next'
function Guide() {
  const { t, i18n } = useTranslation()

  return (
    <>
      <div className='guide--container'>
        <div className='guide--text--container'>
          <div className='guide--header--container'>
            <h1 className='guide--header'>{t('OEMs & Dealers Guide')}</h1>
            <h3 className='guide--secondary--header'>{t('digital transformation')}</h3>
          </div>
        </div>
        <div className='guide--content--container'>
          <div className='guide--cta--container'>
            <div className='guide--cta--left--container'>
              <div className='guide--cta--link guide--email'>
                <span>Email</span>
                <div className='guide--cta--link--image--container'>
                  <img src={email} alt='Email' />
                </div>
              </div>
              <div className='guide--cta--link guide--print'>
                <span>Print</span>
                <div className='guide--cta--link--image--container'>
                  <img src={print} alt='Print' />
                </div>
              </div>
              <div className='guide--cta--link guide--save'>
                <span>Save</span>
                <div className='guide--cta--link--image--container'>
                  <img src={save} alt='Save' />
                </div>
              </div>
            </div>
            <div className='guide--cta--right--container'>
              <div className='guide--share'>{t('Share ')}</div>
              <div className='guide--social--links'>
                <div className='guide--social--link'>
                  <FacebookShareButton url={'https://autoconnect.digital'} quote={'AutoConnect by BeyondCreation'}>
                    <img src={fbBlue} alt='Facebook' />
                  </FacebookShareButton>
                </div>
                <div className='guide--social--link'>
                  <TwitterShareButton url={'https://autoconnect.digital'} title={'AutoConnect by BeyondCreation'}>
                    <img src={twitterBlue} alt='Twitter' />
                  </TwitterShareButton>
                </div>
                <div className='guide--social--link'>
                  <LinkedinShareButton url={'https://autoconnect.digital?latest'}>
                    <img src={linkedInBlue} alt='LinkedIn' />
                  </LinkedinShareButton>
                </div>
                <div className='guide--social--link'>
                  <EmailShareButton url={'https://autoconnect.digital'} subject={'AutoConnect by BeyondCreation'} body=''>
                    <img src={emailBlue} alt='Email' />
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
          <div className='guide--intro--container'>
            <div className='guide--intro--title--container'>
              <p className='guide--intro--title'>{t('Guide h3')}</p>
            </div>
            <div className='guide--intro--paragraph--container'>
              <p className='guide--intro--paragraph'>{t('Shoppers these days')}</p>
              <p className='guide--intro--paragraph'>{t('Customers are therefore')}</p>
              <p className='guide--intro--paragraph'>{t('OEMs and Dealerships need')}</p>
            </div>
          </div>
          <div className='guide--oems--container'>
            <MediaQuery maxWidth={1024}>
              <div className='guide--oems--image--container--mobile'></div>
            </MediaQuery>
            <div className='guide--oems--title--container'>
              <h1 className='guide--oems--title'>{t('OEM Challenge')}</h1>
            </div>
            <div className='guide--oems--text--conatainer'>
              <div className='guide--oems--paragraph--container'>
                <p className='guide--oems--paragraph'>{t('Challenge 1')}</p>
                <p className='guide--oems--paragraph'>{t('Challenge 2')}</p>
                <p className='guide--oems--paragraph'>{t('Challenge 3')}</p>
                <p className='guide--oems--paragraph'>{t('Challenge 4')}</p>
              </div>
              <MediaQuery minWidth={1025}>
                <div className='guide--oems--image--container'>
                  <img src={oem} alt='oem' />
                </div>
              </MediaQuery>
            </div>
          </div>
          <div className='guide--digital--transformation--container'>
            <MediaQuery maxWidth={1024}>
              <div className='guide--digital--transformation--image--container--mobile'></div>
            </MediaQuery>
            <div className='guide--digital--transformation--title--container'>
              <h1 className='guide--digital--transformation--title'>{t('7 basic principles')}</h1>
            </div>
            <div className='guide--digital--transformation--text--container'>
              <MediaQuery minWidth={1025}>
                <div className='guide--digital--transformation--image--container'>
                  <img src={digitalTransformation} alt='digital Transformation' />
                </div>
              </MediaQuery>
              <div className='guide--digital--transformation--paragraph--conatainer'>
                <p className='guide--digital--transformation--paragraph'>{t('principle p-1')}</p>
                <p className='guide--digital--transformation--paragraph'>{t('principle p-2')}</p>
                <p className='guide--digital--transformation--paragraph'>{t('principle p-3')}</p>
                <p className='guide--digital--transformation--paragraph'>{t('principle p-4')} </p>
                <p className='guide--digital--transformation--paragraph'>
                  <span>{t('Principle #1')}: {t('Principle p-1-1')} </span> <br /> {t('Principle p-1-2')}
                </p>
                <p className='guide--digital--transformation--paragraph'>
                  <span>{t('Principle #2')}: {t('Principle p-2-1')} </span> <br /> {t('Principle p-2-2')}
                </p>
                <p className='guide--digital--transformation--paragraph'>
                  <span>{t('Principle #3')}: {t('Principle p-3-1')} </span><br /> {t('Principle p-3-2')}
                </p>
                <p className='guide--digital--transformation--paragraph'>
                  <span>{t('Principle #4')}: {t('Principle p-4-1')} </span><br /> {t('Principle p-4-2')}
                </p>
                <p className='guide--digital--transformation--paragraph'>
                  <span>{t('Principle #5')}: {t('Principle p-5-1')} </span><br /> {t('Principle p-5-2')}
                </p>
                <p className='guide--digital--transformation--paragraph'>
                  <span>{t('Principle #6')}: {t('Principle p-6-1')} </span><br /> {t('Principle p-6-2')}
                </p>
                <p className='guide--digital--transformation--paragraph'>
                  <span>{t('Principle #7')}: {t('Principle p-7-1')} </span><br /> {t('Principle p-7-2')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Guide
