import React, { useContext, useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import './Header.css'
import Burger from '../MobileNav/Burger'
import { scroller } from 'react-scroll'
import { showAreYouInterestedContext } from '../AreYouInterested/AreYouInterestedFormProvider'
import BcLogo from '../../assets/images/bc-logo-white.png'
import AcLogo from '../../assets/images/autoconnect-logo.png'
import languageIcon from '../../assets/icons/language.svg'
import downIcon from '../../assets/icons/down-arrow.svg'
import { useTranslation } from 'react-i18next'
import hatIcon from '../../assets/icons/celebration.png'

function Header() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const changeLanguage = (lang) => {
    console.log(i18n.language)
    i18n.changeLanguage(lang)
    setIsOpen(false)
    window.location.reload()
  }

  const [isOpen, setIsOpen] = useState(false)
  const toggling = () => setIsOpen(!isOpen)
  const [show, setShow] = useState(true)

  let scrollToRequestDemo = () => {
    scroller.scrollTo('scrollTo--form', {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuad',
    })
    hadleShowRequestDemoForm()
  }
  let scrollToDealerGuide = () => {
    scroller.scrollTo('scrollTo--form', {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuad',
    })
    hadleShowDealerGuideForm()
  }
  const { areYouInterested, dealerGuide, requestDemo } = useContext(showAreYouInterestedContext)
  // eslint-disable-next-line
  const [showRequestDemoForm, setshowRequestDemoForm] = requestDemo
  // eslint-disable-next-line
  const [showDealerGuideForm, setshowDealerGuideForm] = dealerGuide
  // eslint-disable-next-line
  const [showAreYouInterested, setshowAreYouInterested] = areYouInterested

  function hadleShowRequestDemoForm() {
    setshowRequestDemoForm(true)
    setshowDealerGuideForm(false)
    setshowAreYouInterested(false)
  }
  function hadleShowDealerGuideForm() {
    setshowRequestDemoForm(false)
    setshowDealerGuideForm(true)
    setshowAreYouInterested(false)
  }
  return (
    <>
    {/* <div className={`new--year d-flex align-items-center justify-content-center w-100 ${(show) ? 'opacity-1' : 'opacity-0'} ` }>
      <span>
      {t('happy-new-year')}
      </span>
      <div className='close' onClick={() => setShow((s) => !s)}>x</div>      
    </div> */}
    <nav className='header--container'>
      <div className='logos--container'>
        <div className='beyond-creation--logo separator'>
          <a href='https://beyond-creation.net/' target='_blank' rel='noreferrer'>
            <img src={BcLogo} alt='Logo' />
          </a>
        </div>
        <div className='auto-connect--logo'>
          <Link to={{ pathname: '/' }}>
            <img src={AcLogo} alt='Logo' />
          </Link>
        </div>
      </div>
      <MediaQuery minWidth={1025}>
        <div className='nav--links--container'>
          <ul className='nav'>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/' exact={true} activeClassName='nav--selected'>
                {t('About AutoConnect')}
              </NavLink>
            </li>
            <li className="nav-item">
                     <NavLink className="nav-link" to="/features" activeClassName="nav--selected">
                        {t("Features-")}
                     </NavLink>
                  </li>
            <li className='nav-item'>
              <NavLink className='nav-link' to='/guide' activeClassName='nav--selected'>
                {t('Digital Transformation Guide')}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className='nav--buttons--container'>
          <button className='nav--dealer--guide--button' value='dealer-guide' onClick={() => scrollToDealerGuide()}>
            {t('Download Guide')}
          </button>
          <button className='nav--request--demo--button' value='request-demo' onClick={() => scrollToRequestDemo()}>
            {t('book a demo')}
          </button>
          <div className='language--toggler'>
            <div onClick={toggling}>
              <img className='arrow--down' src={downIcon} alt='Logo' />
              <img src={languageIcon} alt='Logo' />
              {isOpen && (
                <div className='drop--down'>
                  {i18n.language == 'en' ? (
                    <div className='drop--down--link arabic' onClick={() => changeLanguage('ar')}>
                      عربي
                    </div>
                  ) : (
                    <div className='drop--down--link ' onClick={() => changeLanguage('en')}>
                      English
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <Burger />
      </MediaQuery>
    </nav>
    </>
  )
}

export default Header
