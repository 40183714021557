const en = {
  translation: {
    'digital transformation': 'Digital Transformation',
    'home subtitle': 'A fully integrated, customer-centric retail and ownership experience',
    'about autoConnect': 'About AutoConnect',
    'about autoConnect subtitle':
      'The digital transformation solution for OEMs and dealerships from Beyond Creation will kickstart the process and empower businesses to bypass the research and planning stage and instantly launch a powerful digital solution. It offers a turnkey solution to the digital transformation challenges, based on the 7 Basic Principles of the Ultimate Digital Customer Experience',
    'turnkey main':
      'The digital transformation solution for OEMs and dealerships from Beyond Creation will kickstart the process and empower businesses to bypass the research and planning stage and instantly launch a powerful digital solution.',
    'turnkey solution': 'It offers a turnkey solution to the digital transformation challenges, based on the',
    'turnkey solution-2': '7 Basic Principles of the Ultimate Digital Customer Experience',
    'ce-1': 'A brand-able turnkey solution',
    'ces-1': 'Start with an integrated user experience',

    'ce-2': 'Don’t Overlook the Third-Party Services',
    'ces-2': 'Financing and Insurance are a part of the shopping Journey',

    'ce-3': 'The After-Sales Experience is the Most Important',
    'ces-3': 'Sales and Aftersales in one integrated solution',

    'ce-4': 'Create an omnichannel experience',
    'ces-4': 'Data and Systems Integration is the Fuel for Business Success',

    'ce-1 p':
      'A powerful and scalable solution integrated with the dealership’s Management Systems that offers all vehicle information and stock availability in one place, links models to available merchandise and accessories, and enables online booking for test drives and vehicle purchases. The current feature forms the very basis of an exceptional user experience.',
    'ce-2 p':
      'Being able to offer a broad selection of financing and insurance options is essential to a smooth and seamless purchasing process. The financing and insurance module instantly enable this process.',
    'ce-3 p':
      'The ownership experience has the biggest impact on customer loyalty, far more than the purchase experience. Establishing a fully integrated omnichannel solution across the entire aftersales service offering will be key to ensuring an exceptional ownership experience for customers.',
    'ce-4 p':
      'The digital solution is the engine, but the data is the fuel. The Data HUB module avoids the risk of data silos within different areas of the dealership, and effectively aggregates data from across the entire operation to make it easily available to improve efficiency and the overall online-offline customer experience.',

    'book a demo': 'Book a Demo',
    'Connect with customers': 'Connect with customers online to drive sales and increase customer loyalty to unprecedented levels.',
    'b-1': 'Connect shoppers with OEMs and dealerships online to drive sales',
    'b-2': 'Connect operation systems with customer interactions ',
    'b-3': 'Connect financing with the shopping Journey ',
    'b-4': 'Connect Aftersales with the online experience',
    'b-5': 'Connect online payments with every service ',
    'b-6': 'Connect the customer interface with dealer management systems to offer shoppers a dynamic, transparent, informative and smooth experience ',
    'b-7': 'Connect marketing with data',
    'features sub':
      'AutoConnect is the digital foundation upon which future capabilities will stand. It covers and integrates all customer experiences: researching, financing, buying, insuring and after sales service.',
    'autoconnect features': 'AutoConnect Features ',
    'Learn More': 'Learn More',
    'About AutoConnect': 'About AutoConnect',
    'Download Guide': 'Download Guide',
    'save-': 'Save',
    'add-': 'Add',
    'download-': 'Download',
    'Download-': 'Download',
    'print-': 'Print',
    'demo sub': 'Explore the product experience and learn more about the features and capabilities ',
    'download sub': 'Read the OEMs and dealerships guide to digital transformation ',
    'interested?': '',
    'Shopping experience': 'Shopping Experience',
    'se-1': 'Live stock integration with the DMS   ',
    'se-2': 'Complete product information: specs, multimedia content, brochure, prices, packages and more',
    'se-3': 'Integration with payment gateways ',
    'se-4': 'Vehicle home delivery and licensing services ',
    'se-5': 'Digital showroom using video, voice and chat tools ',
    'se-6': 'Brand merchandise and accessories online shop, and delivery module',
    'Financing & Insurance': 'Finance & Insurance',
    'fi-1': 'Full Integration with 3rd parties service providers ',
    'fi-2': 'View products per model ',
    'fi-3': 'Sort results by installment fees, interest rate and insurance rate',
    'fi-4': 'Choose a financing product',
    'fi-5': 'Submit an initial credit application',
    'fi-6': 'Get SMS and Email notifications on request status ',
    'fi-7': 'Documents review and feedback ',
    'After Sales features': 'After Sales Features',
    'asf-1': 'User accounts to access our vehicle management tool. ',
    'asf-2': 'Book, reschedule and cancel service appointments ',
    'asf-3': 'Apply for car pick-up and drop-off services ',
    'asf-4': 'Apply for courtesy services ',
    'asf-5': 'Service online payment',
    'asf-6': 'View vehicle service history ',
    'asf-7': 'Complaints & Resolution system ',
    'asf-8': 'Service rating ',
    'asf-9': 'Live chat with service advisors',
    'asf-10': 'Parts online shop, and delivery module ',
    'Backend features': 'Backend features',
    'be-1': 'Different admin roles to manage and report all parts of the experience ',
    'be-2': 'Filter, manage and export customers database, new and used sales transactions, customers’ requests, service requests, service payments, customer feedback ',
    'be-3': 'Update, filter and export financing requests status ',
    'be-4': 'Manage customers’ accounts ',
    'be-5':
      'Send admin notifications on new and cancelled orders, test drive and callback requests, new financing credit applications, existing vehicle registration, service booking updates, service payments',
    'be-6': 'Bilingual CMS   ',
    'be-7': 'Generate customized reports ',
    'be-8': 'Enable admin to send customized notifications',
    'digital foundation':
      'AutoConnect is the digital foundation upon which future capabilities will stand. It covers and integrates all customer experiences: researching, financing, buying, insuring and after sales service.',
    'integrate oems': 'We integrate OEMs & dealers’ sales, service and marketing functions. ',
    'explore book': 'Explore the product experience and learn more about the features and capabilities ',
    'read oem': 'Read the OEMs and dealerships guide to digital transformation ',
    'Features-': 'Features',
    'extensive expertise':
      'Beyond Creation has extensive regional expertise managing markets across the Middle East for leading global automotive manufacturers. Based on 15 years of experience in building and rolling out bespoke digital business solutions for OEMs and dealerships, we have created a powerful turnkey solution to digitally transform automotive businesses, offering a flexible, fully integrated, customer-centric, omnichannel retail and ownership experience.',
    'AUTOMOTIVE-': 'AUTOMOTIVE',
    'EXPERIENCE-': 'EXPERIENCE',
    'about-': 'ABOUT',
    'AutoConnect-': 'AutoConnect',
    'Required': 'Required',
    'thank_you_book_appointment_1' : 'Thank you for your interest in AutoConnect!',
    'thank_you_book_appointment_2' : 'Our team will get in touch soon to schedule your demo.',
    'phone_validation' : 'Invalid phone number',
    'email_validation' : 'Invalid email',

    // GUIDE PAGE

    'OEMs & Dealers Guide' : 'OEMs & Dealers Guide to',
    'Guide Header': 'Unlock The Potential of Future Automotive Retail',
    'Guide Subheader': 'The Power of Digital Transformation',
    'Solution by': 'A Solution by Beyond Creation',
    'Guide h3':
      'In the age of digital disruption, digital transformation is not an optional luxury – it is a crucial business strategy for any company that wishes to remain relevant and competitive.',
    'Shoppers these days':
      'Shoppers these days are accustomed to the many seamless retail experiences offered by tech giants such as Amazon, Google or Uber, and expect absolute convenience and transparency in their purchasing journey. ',
    'Customers are therefore':
      'Customers are therefore beginning to expect the same from their automotive experiences too. However, many OEMs and dealerships are lagging in this area. One of the biggest challenges facing dealerships today is how to successfully manage their digital transformation journey. Doing so will involve far more than simply establishing an online showroom or digital payment process.',
    'OEMs and Dealerships need':
      'OEMs and Dealerships need to build a powerful, seamless omnichannel customer experience on the foundations of a strong digital solution. The solution should act as the main data hub to leverage the existing IT infrastructure and deliver on the customer’s basic needs in a cost-efficient and timely manner. To achieve this, OEMs and dealerships must focus on covering the basic elements in creating the ultimate automotive retail and after sales experience through a well-thought-out digital strategy.',
    'OEM Challenge': 'OEMs and Dealerships Challenge',
    'Challenge 1':
      'How to manage the digital transformation journey, is the challenge that most dealerships face today. Ensuring that the journey is future-proof and creates an omnichannel experience for customers is no easy feat and requires a fundamental shift in mindset.',
    'Challenge 2':
      'Addressing this initial challenge should NOT be “how to fill the gap” between the physical and digital experience, as tempting as it may be. This all too often just leads to the most basic integration between a website and a digital payment gateway. The answer lies in a complete restructuring of the customer journey, from the very first touchpoint and throughout the ownership experience.',
    'Challenge 3':
      'An additional challenge dealerships face is that the structure involves multiple entities, with their own legacy systems. This often leads to inconsistencies and breaks in the customer journey, which in turn creates a cumbersome purchase and ownership experience. In particular, the lack of online-offline integration is perceived by customers as a significant shortcoming of the traditional model. This structure also leads to data ‘silos’, with the result that customer and sales data is not used effectively to enhance the customer experience and business efficiency.',
    'Challenge 4': 'Up until today, no tried and tested blueprint for OEMs and dealerships digital transformation exists.',
    '7 basic principles': 'A digital transformation strategy built on the 7 Basic Principles of the Ultimate Digital Customer Experience.',
    'principle p-1':
      'Digital transformation revolves entirely around the customer and should therefore be built around the transformation of the customer experience. This must be reflected in a solid digital strategy and a roadmap for the digitalization of processes in all areas of the business.',
    'principle p-2':
      'The ultimate objective is to adequately respond to today’s customer expectations; digital transformation should pave the way for an omnichannel experience which is the cornerstone of a superior customer journey. ',
    'principle p-3':
      'This guide breaks down the customer journey throughout the product lifecycle to establish the 7 Basic Principles of the Ultimate Digital Customer Experience, and offers a functional solution based on these principles. The principles cover every key stage in the customer journey, from the user experience during the initial touchpoints in the purchase decision making process through to third party services, aftersales, data aggregation and digital marketing strategy. ',
    'principle p-4':
      'The digital transformation solution from Beyond Creation is built on these 7 Basic Principles to offer features and functionalities which make up the essential requirements of a powerful digital solution.',
    'Principle #1': 'Principle #1',
    'Principle #2': 'Principle #2',
    'Principle #3': 'Principle #3',
    'Principle #4': 'Principle #4',
    'Principle #5': 'Principle #5',
    'Principle #6': 'Principle #6',
    'Principle #7': 'Principle #7',
    'Principle p-1-1': 'Make No Room for A Poor User Experience',
    'Principle p-1-2': 'Bad UX/UI and incomplete information are instant turn-offs.',
    'Principle p-2-1': 'Price Transparency is Essential',
    'Principle p-2-2': 'Opaque and shady pricing practices will sour a buyer’s experience.',
    'Principle p-3-1': 'Don’t Overlook the Third-Party Services',
    'Principle p-3-2': 'Financing and insurance options are an essential part of the purchasing process.',
    'Principle p-4-1': 'The After-Sales Experience is the Most Important',
    'Principle p-4-2': 'The ownership experience has the biggest impact on customer loyalty.',
    'Principle p-5-1': 'Data and Systems Integration is the Fuel for Business Success',
    'Principle p-5-2': 'The digital solution is the engine, but the data is the fuel.',
    'Principle p-6-1': 'Digital Strategy Goes Nowhere Without Operational Excellence',
    'Principle p-6-2': 'A system is only as good as the people operating it.',
    'Principle p-7-1': 'Establish the Ultimate Digital Marketing Strategy',
    'Principle p-7-2': 'Even the best processes are pointless if customers aren’t noticing you.',
    'Copyrights-': 'Copyrights',

    'Company placeholder': 'Company',
    'Title placeholder': 'Title',

    //Features page

    'Customer': 'Customer',

    'li_vehicles_customer_1': 'Brand-able single/multi brand digital showroom.',
    'li_vehicles_customer_2': 'Product catalogue content management system.',
    'li_vehicles_customer_3': 'Integrated Stock availability.',
    'li_vehicles_customer_4': 'Online booking and payment.',
    
    'li_vehicles_operation_1': 'Customer order management.',
    'li_vehicles_operation_2': 'Create customer orders and accounts.',
    'li_vehicles_operation_3': 'Admin order notification.',
    'li_vehicles_operation_4': 'Order status management.',
    'li_vehicles_operation_5': 'Management dashboard.',

    'li_sales_customer_1': 'Vehicle management tool.',
    'li_sales_customer_2': 'Onboarding brand owners and current dealership customers.',
    'li_sales_customer_3': 'Integrated service appointments availability.',
    'li_sales_customer_4': 'Instant confirmation on service appointments.',
    'li_sales_customer_5': 'Integrated service invoicing and payment.',
    'li_sales_customer_6': 'SMS and email notifications.',
    
    'li_sales_operation_1': 'Approve new customers.',
    'li_sales_operation_2': 'Edit service appointments.',
    'li_sales_operation_3': 'Auto/manual appointment confirmation .',
    'li_sales_operation_4': 'Create pro-forma invoice.',
    'li_sales_operation_5': 'Admin booking and payment notifications.',
    'li_sales_operation_6': 'Admin Smart alerts.',
    'li_sales_operation_7': 'Managment dashboard.',

    'li_finance_customer_1': 'Integrated financing products with market service providers.',
    'li_finance_customer_2': 'Filter and choose financing product.',
    'li_finance_customer_3': 'Submit credit application.',
    
    'li_finance_operation_1': 'Financing requests notification.',
    'li_finance_operation_2': 'Request management.',
    'li_finance_operation_3': 'Request status management.',

    'happy-new-year': '🎉 We wish you a happy 2022! Connected to everything you would like to achieve 🎉'
    
  },
}
const ar = {
  translation: {
    //pages

    // Guide Page
    'Guide Header': 'دور التحول الرقمي في تعزيز مبيعات السيارات ',
    'Guide Subheader': 'لدى الوكلاء ومواكبة المستقبل ',
    'Shoppers these days':
      ' اعتاد المتسوقون اليوم على تجربة شراء سلسة تقدمها شركات التكنولوجية العملاقة مثل أمازون أو أبل أو أوبر، وأصبحوا يتوقعون أعلى مستويات الراحة والشفافية طوال رحلتهم الرقمية، لا سيما عند التسوق وشراء المنتجات بما في ذلك السيارات. ',
    'Guide h3':
      'لم يعد التحول الرقمي من الكماليات الترفيهية في عصر التكنولوجيا المتطورة والبيانات الضخمة، بل أصبح يمثل استراتيجية لا بد منها لأي شركة تريد مواكبة المستجدات والحفاظ على قدرتها التنافسية. ',
    'Solution by': '',
    'Customers are therefore':
      'إلا أن العديد من شركات صناعة السيارات ووكلائها يواجهون بعض التحديات في هذا المجال، ومنها كيفية إدارة عملية التحول الرقمي، نظراً لأن الأمر لا يقتصر فقط على إنشاء صالة عرض افتراضية أو توفير إجراءات رقمية للدفع. ',
    'OEMs and Dealerships need':
      'وينبغي على شركات صناعة السيارات ووكلائها تقديم خدمات متطورة تتسم بالمرونة والسلاسة بالاعتماد على الحلول الرقمية المتطورة، مع التركيز على العناصر الأساسية التي تضمن الارتقاء بانطباع العملاء وتعزيز تجربتهم أثناء عملية البيع وطوال فترة تملك السيارة.',
    'OEM Challenge': 'التحدي',
    'Challenge 1':
      'يواجه معظم وكلاء السيارات تحدياً في كيفية إدارة عملية التحول الرقمي، إلا أن ضمان استمرارية هذه العملية في المستقبل وتوفير خدمات متعددة القنوات للعملاء ليس مهمة سهلة ويتطلب تغييراً جذرياً في طريقة التفكير. ',
    'Challenge 2':
      'يجب ألا يكون التعامل مع هذا التحدي مبنياً على أساس "كيفية سد الفجوة" بين الخدمات التقليدية والخدمات الافتراضية، لأن هذه الطريقة غالباً ما تكتفي بدمج بسيط بين موقع إلكتروني وبوابة دفع إلكترونية، بل يكمن الحل في إعادة هيكلة عملية الشراء بأكملها، بدءاً من أول مرحلة من مراحل التواصل وطوال فترة تملك السيارة. ',
    'Challenge 3':
      'ومن التحديات الأخرى التي يواجهها وكلاء السيارات هيكل انظمة العمل الذي يشمل أنظمة مختلفة وغير مدمجة تعتمد عليها الأقسام المختلفة داخل الشركة، ويؤدي هذا الأمر إلى حدوث تضارب وانقطاع في رحلة العملاء، مما يؤثر سلباً على انطباعاتهم أثناء عملية الشراء وتملك السيارة. ويرى العملاء أن غياب الدمج بين عمليات البيع الرقمية والتقليدية يمثل ضعفاً كبيراً سببه أسلوب العمل التقليدي. كما أن هذا النظام يؤدي إلى عدم توحيد بيانات العملاء والمبيعات وبالتالي عدم الاستفادة منها كما يجب لتحسين انطباع العملاء ورفع كفاءة العمل. ',
    'Challenge 4': 'لا توجد حتى اليوم خارطة طريق مجربة وموثوقة في مجال التحول الرقمي لشركات صناعة  السيارات ووكلائها. ',
    '7 basic principles': 'استراتيجية تحول رقمي قائمة على المبادئ الأساسية السبعة للارتقاء برحلة العملاء الرقمية ',
    'principle p-1':
      'يتمحور التحول الرقمي بمجمله حول العملاء، ويجب أن يكون الهدف منه الارتقاء بانطباعاتهم. ويتطلب ذلك وضع استراتيجية رقمية متطورة وخارطة طريق واضحة لرقمنة العمليات في جميع مجالات العمل. ',
    'principle p-2': 'إن الهدف الأسمى هو تلبية توقعات العملاء بأفضل طريقة ممكنة، ويجب أن يفتح التحول الرقمي المجال أمام تجربة متعددة القنوات لأنها تعتبر الأساس في توفير خدمات مميزة للعملاء. ',
    'principle p-3':
      'يستعرض هذا البحث بالتفصيل رحلة العميل طوال دورة حياة المنتج ويهدف إلى تعزيز المبادئ الأساسية السبعة للارتقاء برحلة العملاء الرقمية، ويوفر حلاً عملياً مبنياً على هذه المبادئ التي تغطي جميع المراحل الرئيسية في رحلة العميل، بدءاً من انطباع العميل عند التواصل مع الشركة للمرة الأولى لاتخاذ قرار الشراء، وصولاً إلى مزودي الخدمات الأخرى مثل التأمين والتمويل، وخدمات ما بعد البيع، وتجميع البيانات، واستراتيجية التسويق الرقمي.  ',
    'principle p-4':
      'يعتمد حل التحول الرقمي الذي تقدمه شركة Beyond Creation على هذه المبادئ الأساسية السبعة، ويهدف إلى تقديم حل عملي فعّال انطلاقاً من المتطلبات الرئيسية التي يجب على الشركات تلبيتها. ',
    'Principle #1': 'المبدأ الأول',
    'Principle #2': 'المبدأ الثاني',
    'Principle #3': 'المبدأ الثالث',
    'Principle #4': 'المبدأ الرابع',
    'Principle #5': ' المبدأ الخامس',
    'Principle #6': 'المبدأ السادس',
    'Principle #7': 'المبدأ السابع',
    'Principle p-1-1': 'تجنب الثغرات التي تؤثر على انطباع العملاء والمستخدمين',
    'Principle p-1-2': 'الخدمات الرقمية غير المدمجة/و واجهة المستخدم السيئة ونقص المعلومات هي من المشاكل التي تؤثر بشكل مباشر.',
    'Principle p-2-1': 'ضرورة عرض الأسعار بوضوح',
    'Principle p-2-2': 'أساليب التسعير الغامضة تؤدي إلى إرباك العملاء الذي يفكرون بالشراء.',
    'Principle p-3-1': 'لا تنسى مزودي الخدمات الأخرى',
    'Principle p-3-2': 'خدمات التأمين والتمويل هي جزء أساسي من عملية الشراء.',
    'Principle p-4-1': 'انطباع العملاء عن خدمات بعد البيع هو الأهم',
    'Principle p-4-2': 'انطباع العملاء طوال فترة تملك السيارة له أثر كبير على ولائهم للعلامة التجارية.',
    'Principle p-5-1': 'تكامل الأنظمة والبيانات هو أساس نجاح العمل ',
    'Principle p-5-2': 'الحلول الرقمية هي المحرك، والبيانات وقودها.',
    'Principle p-6-1': ': لن تكتمل الاستراتيجية الرقمية إن لم تكن العمليات التشغيلية مميزة',
    'Principle p-6-2': 'نجاح نظام العمل يعتمد على نجاح من ينفذه. ',
    'Principle p-7-1': 'لا بد من وضع استراتيجية مدروسة للتسويق الرقمي ',
    'Principle p-7-2': 'حتى أفضل أساليب العمل ستكون بلا جدوى إذا لم تسلط عليها الضوء ليراها العملاء.',
    'Required': 'هذا الحقل مطلوب',
    'thank_you_book_appointment_1' : 'شكراً على اهتمامك بحل AutoConnect!',
    'thank_you_book_appointment_2' : 'سيتواصل فريق العمل معك لحجز نسختك التجريبية.',
    'phone_validation' : 'رقم الهاتف خاطئ',
    'email_validation' : 'بريد الكتروني خاطئ',
    // ------------
    'about-': 'نبذة عن ',
    'AutoConnect-': 'حل AutoConnect الرقمي',
    'AUTOMOTIVE-': 'مجال ',
    'EXPERIENCE-': 'السيارات',
    'extensive expertise':
      'شركة Beyond Creation هي من الشركات المتخصصة في إدارة أسواق الشرق الأوسط لكبرى شركات السيارات العالمية. تتمتع الشركة بخبرة واسعة تمتد على 15 عاماً في إعداد وتنفيذ الحلول الرقمية لشركات صناعة السيارات ووكلائها، وقد ابتكرت حلاً فريداً من نوعه للتحول الرقمي ويهدف إلى تقديم خدمات رقمية متكاملة تركز على العميل وتتسم بالمرونة في مختلف القنوات بهدف الارتقاء بانطباع العملاء مع التركيز على متطلباتهم عند الشراء وطوال مدة امتلاك السيارات.',
    'home subtitle': 'خدمة متكاملة تركز على العميل لتجربة استثنائية عند شراء السيارة وطوال فترة تملكها  ',
    'about autoConnect': 'نبذة عن حل AutoConnect الرقمي',
    'about autoConnect subtitle':
      'صُمم حل التحول الرقمي الذي تقدمه Beyond Creation لشركات صناعة السيارات ووكلائها ليساعد على بدء الخطوات الفعلية لعملية التحول الرقمي، وتمكين الشركات من تجاوز مرحلة البحث والتخطيط، والقدرة على إطلاق حل رقمي مميز على الفور، بهدف مواجهة تحديات التحول الرقمي بالاعتماد على المبادئ الأساسية السبعة لتجربة رقمية فائقة للعملاء',
    'turnkey main': 'صُمم حل التحول الرقمي الذي تقدمه Beyond Creation لشركات صناعة السيارات ووكلائها ليساعد على بدء الخطوات الفعلية لعملية التحول الرقمي، وتمكين الشركات من تجاوز مرحلة البحث والتخطيط',
    'turnkey solution': ' القدرة على إطلاق حل رقمي مميز على الفور، بهدف مواجهة تحديات التحول الرقمي بالاعتماد على',
    'turnkey solution-2': 'المبادئ الأساسية السبعة لتجربة رقمية فائقة للعملاء',
    'ce-1': 'حل رقمي جاهز يمكن تخصيصه حسب متطلبات العلامة التجارية',
    'ce-2': 'لا تنسى مزودي الخدمات الأخرى ',
    'ce-3': 'انطباع العملاء عن خدمات بعد البيع هو الأهم',
    'ce-4': 'تأكد من تقديم خدمات متعددة القنوات للعملاء',
    'ce-5': 'المبيعات وخدمات ما بعد البيع في حل واحد متكامل ',
    'ce-6': 'تأكد من تقديم خدمات متعددة القنوات للعملاء',
    'ce-7': 'تكامل الأنظمة والبيانات هو أساس نجاح العمل ',
    'ces-1': 'ابدأ من تشكيل انطباع العملاء الشامل',

    'ces-2': 'خدمات التمويل والتأمين هي جزء من عملية الشراء',

    'ces-3': 'المبيعات وخدمات ما بعد البيع في حل واحد متكامل',

    'ces-4': 'تكامل الأنظمة والبيانات هو أساس نجاح العمل',

    'ce-1 p':
      'حل قوي وقابل للتطوير يتكامل مع أنظمة إدارة الوكيل (DMS) ويوفر جميع المعلومات عن السيارات وتوافر المخزون في مكان واحد، ويربط الطرازات بالمنتجات والملحقات المتاحة، ويتيح إمكانية الحجز عبر الإنترنت لتجربة القيادة وشراء السيارات. تشكل الميزة الحالية الأساس لتوفير خدمات استثنائية للعملاء. ',
    'ce-2 p':
      'ينبغي امتلاك القدرة على توفير مجموعة واسعة من خيارات التمويل والتأمين. يساعد الحل الذي يتضمن خيارات التأمين والتمويل في التغلب على نقاط الضعف وتزويد العملاء بمجموعة فورية من خيارات التمويل والتأمين من مختلف المزودين ضمن عملية الشراء.',
    'ce-3 p':
      'يؤثر انطباع العملاء خلال فترة تملك السيارة بصورة كبيرة على ولاء العملاء ويفوق الانطباع عن عملية الشراء نفسها، ومن المهم أيضاً وضع حل متكامل ومتعدد القنوات في جميع خدمات ما بعد البيع لضمان حصول العملاء على تجربة استثنائية طوال فترة تملك السيارة. ',
    'ce-4 p':
      'يساهم المركز الموحّد للبيانات في تجنب مخاطر عدم توحيد البيانات وتشتتها بين مختلف أقسام الشركة والعجز عن استخدامها بالصورة الأمثل، ويجمع البيانات بكفاءة عالية في جميع مراحل العمل والتواصل لتسهيل توفيرها ورفع الكفاءة وتحسين تجربة العملاء. ',

    'book a demo': 'اطلب نسخة تجريبية ',
    'Connect with customers': 'تواصل مع العملاء على الإنترنت لزيادة المبيعات والارتقاء بولاء العملاء إلى مستويات استثنائية ',
    'b-1': 'اربط المتسوقين والعملاء إلكترونياً مع شركات صناعة السيارات ووكلائها لزيادة حجم المبيعات ',
    'b-2': 'اربط أنظمة التشغيل مع جميع تفاعلات العميل',
    'b-3': 'أضف خيارات التمويل إلى عملية التسوق',
    'b-4': 'اربط خدمات ما بعد البيع مع الخدمات الإلكترونية عن طريق أداة متطورة لإدارة السيارة ',
    'b-5': 'اربط خدمة الدفع الإلكتروني مع جميع الخدمات الأخرى',
    'b-6': 'اربط واجهة المستخدم مع أنظمة إدارة الوكيل (DMS)حتى يتمتع المتسوق بخدمات واضحة وسهلة ومفيدة تحتوي على جميع المعلومات الضرورية ',
    'b-7': 'اربط التسويق مع البيانات المتوفرة ',
    'features sub':
      'صمم حل AutoConnect للتحول الرقمي ليكون الأساس الرقمي الذي سترتكز عليه خدماتك المستقبلية. يساعد هذا الحل الرقمي على دمج جميع الخدمات التي تقدم للعملاء دون استثناء بما في ذلك البحث والتمويل والشراء والتأمين وخدمات ما بعد البيع.  ',
    'autoconnect features': 'مزايا حل AutoConnect للتحول الرقمي',
    'Learn More': 'اعرف المزيد ',
    'About AutoConnect': 'نبذة عن حل AutoConnect الرقمي',
    'Download Guide': 'تنزيل الدليل',
    'save-': 'أضف',
    'add-': 'احفظ',
    'download-': 'نزّل ',
    'print-': 'اطبع',
    'demo sub': 'تعرّف على عملية شراء المنتجات واعرف المزيد عن الخصائص والإمكانيات',
    'download sub': 'تفضل بقراءة دليل التحول الرقمي لشركات صناعة السيارات ووكلائها',
    'interested?': '',
    'Shopping experience': 'انطباع المتسوقين',
    'se-1': 'دمج مباشر لبيانات المخزون مع نظام إدارة الوكيل (DMS)',
    'se-2': 'معلومات كاملة عن المنتجات مثل المواصفات الفنية ومحتوى الوسائط المتعددة والكتيبات والأسعار والعروض وغيرها ',
    'se-3': 'الدمج مع بوابات الدفع',
    'se-4': 'توصيل السيارة وخدمة التسجيل',
    'se-5': 'صالة العرض الرقمية باستخدام أدوات التواصل الصوتي والمرئي والدردشة',
    'se-6': 'متجر إلكتروني للمنتجات والملحقات ومنصة للتوصيل',
    'Financing & Insurance': 'التمويل والتأمين',
    'fi-1': 'لدمج الكامل مع مزودي الخدمات الأخرى ',
    'fi-2': 'مشاهدة المنتجات الخاصة بكل طراز ',
    'fi-3': 'عرض النتائج حسب قيمة الأقساط، ومعدل الفائدة وسعر التأمين ',
    'fi-4': 'اختيار خدمة التمويل المناسبة ',
    'fi-5': 'تقديم طلب أولي للتمويل ',
    'fi-6': 'استلام إشعارات الرسائل القصيرة والبريد الإلكتروني بخصوص حالة الطلب ',
    'fi-7': 'مراجعة المستندات والحصول على النتيجة ',

    'After Sales features': 'خدمات ما بعد البيع',
    'asf-1': 'حسابات للمستخدمين تتيح لهم الوصول إلى نظام إدارة السيارة ',
    'asf-2': 'حجز مواعيد الصيانة وتغييرها وإلغائها ',
    'asf-3': 'طلب خدمات استلام السيارة وتوصيلها ',
    'asf-4': 'طلب الخدمات الإضافية ',
    'asf-5': 'سداد قيمة الخدمة إلكترونياً ',
    'asf-6': 'عرض سجل صيانة السيارة ',
    'asf-7': 'نظام الشكاوى وحلها ',
    'asf-8': 'تقييم الخدمة ',
    'asf-9': 'دردشة مباشرة مع مستشاري الخدمة ',
    'asf-10': 'متاجر قطع الغيار على الإنترنت ومنصات التوصيل ',

    'Backend features': 'Backend features',
    'be-1': 'أدوار مختلفة لمسؤولي الحل الرقمي لإدارة جميع مراحل الخدمة والإبلاغ عنها ',
    'be-2': 'ترتيب وإدارة واستخراج قواعد بيانات العملاء وعمليات بيع السيارات الجديدة والمستعملة، وطلبات العملاء، وطلبات الخدمة، وسداد دفعات الخدمة وآراء العملاء',
    'be-3': 'تحديث وترتيب واستخراج حالة طلبات التمويل ',
    'be-4': 'إدارة حسابات العملاء ',
    'be-5': 'إرسال إشعارات إدارية بخصوص الطلبات الجديدة والملغاة، وطلبات التمويل الجديدة، وتسجيل السيارات الموجودة، ومستجدات حجز الخدمات، وتسدد دفعات الخدمات ',
    'be-6': 'نظام إدارة العملاء ثنائي اللغة ',
    'be-7': 'إصدار تقارير مخصصة ',
    'be-8': 'تمكين مسؤول الحل الرقمي من إرسال إشعارات مخصصة ',

    'digital foundation':
      'صمم حل AutoConnect للتحول الرقمي ليكون الأساس الرقمي الذي سترتكز عليه خدماتك المستقبلية. يساعد هذا الحل الرقمي على دمج جميع الخدمات التي تقدم للعملاء دون استثناء بما في ذلك البحث والتمويل والشراء والتأمين وخدمات ما بعد البيع.  ',
    'integrate oems': 'يضمن الحل الرقمي المبتكر دمج خدمات البيع والصيانة والتسويق التي تقدمها شركات صناعة السيارات ووكلائها في مكان واحد',
    'explore book': 'تعرّف على عملية شراء المنتجات واعرف المزيد عن الخصائص والإمكانيات ',
    'read oem': 'تفضل بقراءة دليل التحول الرقمي لشركات صناعة السيارات ووكلائها ',
    'Features-': 'المزايا ',
    'digital transformation': 'التحول الرقمي ',

    'Are you Interested?': 'هل أنت مهتم بالحل الجديد؟',
    'Book a': 'اطلب',
    'Demo ': 'نسخة تجريبية',
    'Download ': 'تنزيل',
    'Guide ': 'الدليل',
    'Download Now': 'نزّل الآن',
    'Download-': 'نزّل الآن',
    'Book Now': 'قدم الآن',
    'Subscribe to our Newsletter': 'اشترك في نشرتنا الاخبارية',
    'Your Email Address': 'بريدك الالكتروني',
    'Subscribe ': 'اشترك',
    'BENEFITS ': 'المزاية',
    'Solution': 'اهم',
    'Highlights': 'الخصائص',
    'Solution Highlights': 'اهم الخصائص',
    'FEATURES ': 'AutoConnect',
    'Digital Transformation Guide': 'دليل التحول الرقمي',
    '15 years of experience': '15 سنة من الخبرة الرقمية',
    'Egypt Office': 'مكتب القاهرة ',
    '95D, El-Marghany St., Helioplis, Cairo.': ' ٩٥د شارع املرغني مصر الجديدة القاهرة، مصر',
    'DMCC Business Centre, AG Tower, Dubai.': 'رقم الوحدة: F1 ،مركز أعمال DMCC املستوى ١٣ ، برج AG دبى، الامارات العربية املتحدة',
    'UAE Office': 'مكتب دبي ',
    'Copyrights-': 'حقوق الطبع والنشر',
    '2021. All Rights Reserved': '2021 جميع الحقوق محفوظة ',
    'Please fill-in the below form to book an appointment': 'يرجى ملء النموذج أدناه لحجز موعد',
    'Book Appointment': 'إحجز الموعد',
    'Full Name': 'الاسم بالكامل ',
    'Phone Number': 'رقم الهاتف',
    'E-mail Address': 'البريد الالكتروني',
    'Company placeholder': 'الشركة',
    'Title placeholder': 'لقب',
    'If the file did not download automatically': 'إذا لم يتم تنزيل الملف تلقائيًا',
    'Please fill-in the below form to download your copy': 'يرجى ملء النموذج أدناه لتنزيل نسختك',
    'Click here!': 'إضغط هنا!',
    'Thank you!': 'شكراً!',
    'Share ': 'شارك',
    'OEMs & Dealers Guide': 'التحول الرقمي لشركات صناعة السيارات ووكلائها',
    'AutoConnect': 'خصائص و امكانيات',

    //Features Page

    'Customer':'ميزات العملاء',
    'Operation': 'ميزات التشغيل',
    'Shopping Experience': 'تجربة تسوق السيارات',
    'After Sales': 'خدمات ما بعد البيع',
    'Financing': 'التمويل',

    'li_vehicles_customer_1': 'صالة عرض رقمية ذات علامة تجارية واحدة / متعددة العلامات التجارية.',
    'li_vehicles_customer_2': 'نظام إدارة محتوى كتالوج المنتجات.',
    'li_vehicles_customer_3': 'توافر المخزون المتكامل.',
    'li_vehicles_customer_4': 'الحجز والدفع عبر الإنترنت.',
    
    'li_vehicles_operation_1': 'إدارة طلبات العملاء.',
    'li_vehicles_operation_2': 'إنشاء أوامر طلبات العملاء والحسابات.',
    'li_vehicles_operation_3': 'إشعار الطلبات.',
    'li_vehicles_operation_4': 'إدارة حالة الطلب.',
    'li_vehicles_operation_5': 'لوحة البيانات والتقارير .',

    'li_sales_customer_1': 'أداة إدارة السيارات.',
    'li_sales_customer_2': 'تفعيل حسابات ملاك العلامات التجارية والعملاء الحاليين.',
    'li_sales_customer_3': 'توافر مواعيد الخدمة المتكاملة.',
    'li_sales_customer_4': 'تأكيد فوري على مواعيد الخدمة.',
    'li_sales_customer_5': 'خدمة الفواتير والدفع الالكترونى المتكاملة.',
    'li_sales_customer_6': 'إشعارات الرسائل القصيرة والبريد الإلكتروني.',
    
    'li_sales_operation_1': 'الموافقة على العملاء الجدد.',
    'li_sales_operation_2': 'تحرير مواعيد الخدمة للعملاء.',
    'li_sales_operation_3': 'تأكيد الموعد التلقائي / اليدوي.',
    'li_sales_operation_4': 'إنشاء فاتورة مبدئية.',
    'li_sales_operation_5': 'إخطارات الحجز والدفع.',
    'li_sales_operation_6': 'تنبيهات المسؤول الذكية.',
    'li_sales_operation_7': 'لوحة البيانات والتقارير.',

    'li_finance_customer_1': 'منتجات تمويل متكاملة مع مزودي الخدمات.',
    'li_finance_customer_2': 'تصفية واختيار منتج التمويل.',
    'li_finance_customer_3': 'إرسال طلب الائتمان.',
    
    'li_finance_operation_1': 'إخطار طلبات التمويل.',
    'li_finance_operation_2': 'إدارة الطلبات.',
    'li_finance_operation_3': 'إدارة حالة الطلبات.',

    'happy-new-year': '🎉 نتمنى لكم عام 2022 سعيداً! متصل بكل ما تود تحقيقه. 🎉'
  },
}

const data = { ar, en }

export default data
