import React from 'react'
import './FeaturesBlock.css'
import Square from '../Square/Square'

function FeaturesBlock({ data, reverse }) {
  return (
    <>
      <div className={'features--block--container'}>
        {/* <div className='features--block--image--container'>
          <div className='features--block--image' />
          <div className={reverse ? 'features--block--img--underlay--sqaure--container__reverse ' : 'features--block--img--underlay--sqaure--container'}>
            <Square squareClasses={'img-underlay'} />
          </div>
        </div> */}
        <div className={'features--block--text--container'}>
          <div className='features--block--header--container'>
            <h1 className='features--block--header'>{data.header_1}</h1>
          </div>
          <ul className='features--block--features--list'>
            {data.listItems_customer &&
              data.listItems_customer.map((listItems_customer) => {
                return (
                  <li key={listItems_customer} className='features--block--features--list--item'>
                    {listItems_customer}
                  </li>
                )
              })}
          </ul>
          {/* <div className={reverse ? 'features--block--sqaure--container__reverse' : 'features--block--sqaure--container'}>
            <Square squareClasses={'medium'} />
          </div> */}
        </div>
        <div className={'features--block--text--container'}>
          <div className='features--block--header--container'>
            <h1 className='features--block--header'>{data.header_2}</h1>
          </div>
          <ul className='features--block--features--list'>
            {data.listItems_operation &&
              data.listItems_operation.map((listItem_operation) => {
                return (
                  <li key={listItem_operation} className='features--block--features--list--item'>
                    {listItem_operation}
                  </li>
                )
              })}
          </ul>
          {/* <div className={reverse ? 'features--block--sqaure--container__reverse' : 'features--block--sqaure--container'}>
            <Square squareClasses={'medium'} />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default FeaturesBlock
