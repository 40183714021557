import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import './CustomerExperience.css'
import Square from '../Square/Square'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

SwiperCore.use([Navigation, Pagination, Scrollbar])
function CustomerExperience() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()

  return (
    <>
      <MediaQuery maxWidth={1024}>
        <div className='customer--experience--header--container'>
          <h1 className='customer--experience--header'>{t('Solution')}</h1>
          <h1 className='customer--experience--header'>{t('Highlights')}</h1>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={1025}>
        <div className='customer--experience--header--container'>
          <h1 className='customer--experience--header'>{t('Solution Highlights')}</h1>
        </div>
      </MediaQuery>
      <div className='customer--experience--container'>
        <Swiper
          spaceBetween={0}
          slidesPerView={1.6}
          // centeredSlides={true}
          pagination={{
            type: 'progressbar',
            clickable: true,
          }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            1025: {
              centeredSlides:true,
              slidesPerView: 1.8,
            },
          }}
        >
          <SwiperSlide>
            <div className='customer--experience--slider--content'>
              {/* <img src={customer1} alt="Customer 1" /> */}
              <div className='customer--experience--slider--text--container'>
                <div className='customer--experience--slide--text--container'>
                  <div className='customer--experience--slide--text--header'>{t('ce-1')}</div>
                  <div className='customer--experience--slide--text--header customer--experience--slide--text--subheader'>{t('ces-1')}</div>
                  <div className='customer--experience--slide--text--paragraph'>
                    <p>{t('ce-1 p')}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='customer--experience--slider--content'>
              {/* <img src={customer1} alt="Customer 1" /> */}
              <div className='customer--experience--slider--text--container'>
                <div className='customer--experience--slide--text--container'>
                  <div className='customer--experience--slide--text--header'>{t('ce-2')}</div>
                  <div className='customer--experience--slide--text--header customer--experience--slide--text--subheader'>{t('ces-2')}</div>
                  <div className='customer--experience--slide--text--paragraph'>
                    <p>{t('ce-2 p')}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='customer--experience--slider--content'>
              {/* <img src={customer1} alt="Customer 1" /> */}
              <div className='customer--experience--slider--text--container'>
                <div className='customer--experience--slide--text--container'>
                  <div className='customer--experience--slide--text--header'>{t('ce-3')}</div>
                  <div className='customer--experience--slide--text--header customer--experience--slide--text--subheader'>{t('ces-3')}</div>
                  <div className='customer--experience--slide--text--paragraph'>
                    <p>{t('ce-3 p')}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='customer--experience--slider--content'>
              {/* <img src={customer1} alt="Customer 1" /> */}
              <div className='customer--experience--slider--text--container'>
                <div className='customer--experience--slide--text--container'>
                  <div className='customer--experience--slide--text--header'>{t('ce-4')}</div>
                  <div className='customer--experience--slide--text--header customer--experience--slide--text--subheader'>{t('ces-4')}</div>
                  <div className='customer--experience--slide--text--paragraph'>
                    <p>{t('ce-4 p')}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className='customer--experience--square--container--top--one'>
          <Square squareClasses={'medium'} />
        </div>
        <div className='customer--experience--square--container--middle--one'>
          <Square squareClasses={'small'} />
        </div>
        <div className='customer--experience--square--container--bottom--one'>
          <Square squareClasses={'small'} />
        </div>
        <div className='customer--experience--square--container--top--two'>
          <Square squareClasses={'medium'} />
        </div>
        <div className='customer--experience--square--container--middle--two'>
          <Square squareClasses={'small'} />
        </div>
        <div className='customer--experience--square--container--bottom--two'>
          <Square squareClasses={'small'} />
        </div>
      </div>
    </>
  )
}

export default CustomerExperience
