import React, { useEffect } from "react";
import BannerImage from "../../components/BannerImage/BannerImage";
import Features from "../../components/Features/Features";
import featuresBg from "../../assets/images/features.jpg";
import { useTranslation } from "react-i18next";

function FeaturesPage() {
   // eslint-disable-next-line
   const { t, i18n } = useTranslation();
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <>
         <BannerImage header={t("integrate oems")} backgroundImage={featuresBg} />
         <Features />
      </>
   );
}

export default FeaturesPage;
