import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import BenefitsCard from '../BenefitsCard/BenefitsCard'
import './Benefits.css'
import MediaQuery from 'react-responsive'
// import icon1 from "../../assets/icons/b1.svg";
import icon2 from '../../assets/icons/b2.svg'
import icon3 from '../../assets/icons/b3.svg'
import icon4 from '../../assets/icons/b4.svg'
import icon5 from '../../assets/icons/b5.svg'
import icon6 from '../../assets/icons/b6.svg'
import icon7 from '../../assets/icons/b7.svg'
import { useTranslation } from 'react-i18next'
import Square from '../Square/Square'
SwiperCore.use([Navigation, Pagination, Scrollbar])
function Benefits() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  return (
    <>
      <div className='benefits--container'>
        <div className='benefits--square--container--top--right'>
          <Square squareClasses={'medium margin-top-50'} />
          <Square squareClasses={'medium'} />
        </div>
        <div className='benefits--text--container'>
          <h1 className='benefits--header'>{t('BENEFITS ')}</h1>
          <div className='benefits--paragraph'>{t('Connect with customers')}</div>
        </div>
        <MediaQuery minWidth={1025}>
          <div className='benefits--cards--container'>
            <BenefitsCard icon={icon2} text={t('b-1')} />
            <BenefitsCard icon={icon7} text={t('b-3')} />
            <BenefitsCard icon={icon4} text={t('b-4')} />
          </div>
          <div className='benefits--cards--container'>
            <BenefitsCard icon={icon5} text={t('b-5')} />
            <BenefitsCard icon={icon3} text={t('b-7')} />
            <BenefitsCard icon={icon6} text={t('b-2')} />
            {/* <BenefitsCard icon={icon1} text={t("b-6")} /> */}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1024}>
          <Swiper
            spaceBetween={10}
            slidesPerView={2}
            pagination={{
              type: 'progressbar',
            }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              320: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 4,
              },
            }}
          >
            <SwiperSlide>
              <BenefitsCard icon={icon2} text={t('b-1')} />
            </SwiperSlide>
            <SwiperSlide>
              <BenefitsCard icon={icon7} text={t('b-3')} />
            </SwiperSlide>
            <SwiperSlide>
              <BenefitsCard icon={icon4} text={t('b-4')} />
            </SwiperSlide>
            <SwiperSlide>
              <BenefitsCard icon={icon5} text={t('b-5')} />
            </SwiperSlide>
            <SwiperSlide>
              <BenefitsCard icon={icon3} text={t('b-7')} />
            </SwiperSlide>
            <SwiperSlide>
              <BenefitsCard icon={icon6} text={t('b-2')} />
            </SwiperSlide>
            {/* <SwiperSlide>
                    <BenefitsCard icon={icon1} text={t("b-6")} />
                  </SwiperSlide> */}
          </Swiper>
        </MediaQuery>
        <div className='benefits--square--container--one'>
          <Square squareClasses={'medium'} />
        </div>
        <div className='benefits--square--container--two'>
          <Square squareClasses={'medium'} />
          <Square squareClasses={'medium'} />
          <Square squareClasses={'medium'} />
        </div>
      </div>
    </>
  )
}

export default Benefits
