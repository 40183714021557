import React from 'react'
import AboutAutoConnect from '../../components/AboutAutoConnect/AboutAutoConnect'
import AutoConnectFeatures from '../../components/AutoConnectFeatures/AutoConnectFeatures'
import AutoExperience from '../../components/AutoExperience/AutoExperience'
import BannerSlider from '../../components/BannerSlider/BannerSlider'
import Benefits from '../../components/Benefits/Benefits'
import CustomerExperience from '../../components/CustomerExperience/CustomerExperience'

function HomePage() {
  return (
    <>
      <BannerSlider />
      <AutoExperience />
      <AboutAutoConnect />
      <CustomerExperience />
      <Benefits />
      <AutoConnectFeatures />
    </>
  )
}

export default HomePage
