import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, EffectFade } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import './AboutAutoConnect.css'
import carBg from '../../assets/images/bg-car.png'
import carIpad from '../../assets/images/iPad.png'
import Square from '../Square/Square'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

SwiperCore.use([EffectFade, Navigation, Pagination, Scrollbar])

function AboutAutoConnect() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  return (
    <>
      <div className='about--auto--connect--container'>
        <div className='about--auto--connect--header--container'>
          <h1 className='about--auto--connect--header'>{t('about-')}</h1>
          <h1 className='about--auto--connect--header'>{t('AutoConnect-')}</h1>
        </div>
        <div className='about--auto--connect--paragraph'>{t('turnkey main')}</div>
        <div className='about--auto--connect--background' style={{ backgroundImage: `url(${carBg})` }}></div>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation={true}
          effect={'fade'}
          pagination={{
            clickable: true,
            type: 'fraction',
          }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <div className='about--auto--connect--slider--content'>
              <img src={carIpad} alt='Background Car' />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='about--auto--connect--slider--content'>
              <img src={carIpad} alt='Background Car' />
            </div>
          </SwiperSlide>
        </Swiper>
        <div className='about--auto--connect--info--container'>
          <h1 className='about--auto--connect--info'>{t('turnkey solution')}</h1>
          <Link to={{ pathname: '/guide' }}>
            <h1 className='about--auto--connect--info__bold'>{t('turnkey solution-2')}</h1>
          </Link>
          <div className='about--auto--connect--square--container--bottom--right'>
            <Square squareClasses={'medium'} />
            <Square squareClasses={'medium margin-top-50'} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutAutoConnect
